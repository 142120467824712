<template>
  <geocode-type
    :initial-value="initialValue"
    :country-code="countryCode"
    :placeholder="placeholder"
    :css-class="cssClass"
    @place_changed="handleInput"
  >
    <template #prepend>
      <slot />
    </template>
  </geocode-type>
</template>

<script>
import GeocodeType from '../common/Form/GeocodeType.vue';
import { findZone } from '../../services/api/Zone';

export default {
  name: 'SearchWhere',
  components: { GeocodeType },
  props: {
    initialValue: { type: String, required: false, default: null },
    cssClass: { type: String, required: false, default: 'rounded' },
    placeholder: { type: String, required: false, default: 'website.search.where' },
    countryCode: {
      type: String,
      required: false,
      default: 'be',
    },
  },
  emits: ['input'],
  methods: {
    async handleInput(v) {
      function findAddressComponent(components, lookup) {
        return components?.find((c) => c.types.includes(lookup));
      }

      const country = findAddressComponent(v.address_components, 'country');
      // const region = findAddressComponent(v.address_components, 'administrative_area_level_1');
      // const dep = findAddressComponent(v.address_components, 'administrative_area_level_2');
      // const zip = findAddressComponent(v.address_components, 'postal_code');

      // const requiredZoneTypes = ['administrative_area_level_2',
      // 'administrative_area_level_1', 'country'];
      // const isBiggerThanACityAndSmallerThanAContinent = new Set(
      //  requiredZoneTypes.concat(v.types)).size < requiredZoneTypes.length + v.types.length;

      // Gmap result can contain various types. Warn this selection is weak
      const interestingType = v.types?.filter((t) => t !== 'political').pop();
      // Map Gmap types to DB types
      const typesMap = new Map([
        ['country', 'country'],
        ['administrative_area_level_1', 'region'],
        ['administrative_area_level_2', country === 'belgium' ? 'province' : 'departement'],
      ]);

      // if (isBiggerThanACityAndSmallerThanAContinent) {
      //   // Then we must have it in the base
      // } else {
      //   // We will still perform a lookup
      //   // We will check the name
      //   // We may use the parent zone
      //   // Or the geocoordinates
      // }

      // Attempt to find the related zone
      const type = interestingType ? typesMap.get(interestingType) : null;
      const zone = await findZone({
        internalName: v.name,
        ...(type ? { type } : {}),
      });

      const { lat, lng } = v.geometry.location;
      const geocoding = { name: v.name, lat: lat(), lng: lng() };
      this.$emit('input', {
        geocoding,
        zone: zone && Array.isArray(zone) && zone.length ? zone[0] : zone,
      });
    },
  },
};
</script>
