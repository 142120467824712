<template>
  <b-form-datepicker
    v-model="innerValue"
    start-weekday="1"
    reset-button
    today-button
    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
    :min="now"
    :locale="spflocale"
    :placeholder="placeholder"
    :class="cssClass"
  >
    <template #button-content><slot name="button-content"></slot></template>
  </b-form-datepicker>
</template>

<script>
export default {
  name: 'SearchWhen',
  props: {
    cssClass: { type: [String], required: false, default: null },
    placeholder: { type: String, required: false, default: null },
    value: { type: [String, Number, Date], required: false, default: null },
  },
  emits: ['input'],
  computed: {
    now() {
      return new Date();
    },
    innerValue: {
      get() {
        if (this.value instanceof Date) {
          return this.value.format('YYYY-M-D');
        }
        return this.value;
      },
      set(v) {
        this.$emit('input', new Date(v));
      },
    },
  },
};
</script>
